<template>
    <div class="type-goods">
        <div>
            <div class="breadcrumb">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>
                        <span
                            style="cursor: pointer"
                            @click="$router.push({ path: '/' })"
                            >首页</span
                        >
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>退款售后</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="wrap">
            <div class="top">
                <div>
                    <img :src="pic + query.ProPictrue" alt="" />
                </div>
                <div class="top-right">
                    <div>{{ query.ProName }}</div>
                    <div class="sku">{{ query.BarCode }}</div>
                </div>
            </div>
            <div class="bottom">
                <div class="type">选择服务类型</div>
                <div class="list">
                    <div class="list-main">
                        <div class="list-main-wrap" @click="handleToPage(1)">
                            <div>
                                <i class="iconfont icontuikuantuihuo-01"></i>
                            </div>
                            <div class="text">
                                <div class="title">我要退款（无需退货）</div>
                                <div class="info">
                                    没有收到货或与卖家商议同意不用退货只退款
                                </div>
                            </div>
                        </div>
                        <div class="list-main-wrap" @click="handleToPage(2)">
                            <div>
                                <i class="iconfont icontuikuanhuo-02"></i>
                            </div>
                            <div class="text">
                                <div class="title">我要退款（退货退款）</div>
                                <div class="info">
                                    需要根据退货地址寄回商品和发票
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="problem">
            <div class="title">常见问题</div>
            <div>
                1. 卖家不发货，怎么办？
            </div>
            <div>
                亲，如果卖家迟迟不发货，您可以根据目前订单的状态选择对应方式。
            </div>
            <div>
                2. 如何申请退款？
            </div>
            <div>
                亲，根据您的订单状态的不同，申请的方式也是不一样的。
            </div>
            <div>
                3. 申请退款后，交易退款成功，钱退到哪里？
            </div>
            <div>
                亲，要先查看退款状态哦，只有当订单的退款状态显示【退款成功】时，钱款才会退回原先的支付渠道。
            </div>
        </div>
    </div>
</template>

<script>
import qs from 'qs'
export default {
    data() {
        return {
            query: {},
            pic: FILE_URL
        }
    },
    mounted() {
        this.query = this.$route.query
    },
    methods: {
        handleToPage(type) {
            let j = this.$route.query
            j.type = type
            this.$router.push({
                path: `/me/fillIn?${qs.stringify(j)}`
            })
        }
    }
}
</script>

<style lang="less" scoped>
.type-goods {
    width: 1240px;
    margin: 0 auto;
}

.problem {
    margin-top: 50px;
    background: #eeeeee;
    padding: 30px;
    .title {
        font-family: 'ct';
        font-size: 16px;
    }
    div {
        margin: 5px 0;
        color: #666666;
        font-size: 14px;
    }
}

.top {
    display: flex;
    border-bottom: 1px solid #dcdcdc;

    .top-right {
        margin-left: 10px;
    }
}

.list-main {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .list-main-wrap {
        display: flex;
        border: 1px solid #dcdcdc;
        margin: 0 20px;
        padding: 20px;
        cursor: pointer;
        .text {
            margin-left: 10px;
            .title {
                font-family: 'ct';
                font-size: 18px;
            }

            .info {
                font-size: 12px;
                margin-top: 5px;
                color: #999999;
            }
        }

        i {
            font-size: 40px;
            color: #0097ba;
        }
    }
}

.bottom {
    padding: 20px 20px 100px 20px;

    .type {
        font-size: 16px;
    }
}

.sku {
    width: fit-content;
    padding: 2px 10px;
    background: #f5f5f5;
    border: 1px solid #dedede;
    border-radius: 15px;
    color: #333333;
    font-size: 12px;
    margin: 10px 0px;
}

.breadcrumb {
    margin: 20px 0;
}

.sign {
    color: #0097ba;
}

.me-main-center {
    display: flex;
}

.wrap {
    border: 1px solid #dcdcdc;

    .top {
        padding: 20px;
    }
}

.top {
    img {
        width: 100px;
        height: 100px;
        border: 1px solid #dcdcdc;
    }
}
</style>
